export const admin_routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/admin/pages/Login"),
  },
  {
    path: "/logout",
    name: "Logout",
    component: () => import("@/views/admin/pages/Login"),
  },
  // {
  //   path: "/login/:id",
  //   name: "LoginRefferal",
  //   component: () => import("@/views/admin/pages/Login"),
  // },
  {
    path: "/register",
    name: "AdminRegister",
    component: () => import("@/views/admin/pages/Register"),
  },

  {
    path: "/",
    component: () => import("@/views/admin/Layout"),
    meta: {
      middleware: "advisor-auth",
    },
    children: [
      {
        path: "",
        name: "AdminDashboard",
        component: () => import("@/views/admin/pages/Dashboard"),
      },
      // {
      //   path: "dempform",
      //   name: "DemoForm",
      //   component: () => import("@/views/admin/pages/Demo"),
      // },
      // Plan
      {
        path: "plans",
        name: "PlanListing",
        component: () => import("@/views/admin/pages/Plans/Index"),
      },

      // Payment
      {
        path: "payment",
        name: "RzPayment",
        component: () => import("@/views/admin/pages/Payment/Index"),
      },
      // Plan

      // Connects
      {
        path: "connects",
        name: "ConnectsListing",
        component: () => import("@/views/admin/pages/Connects/Index"),
      },
      // Connects


      // Lead
      {
        path: "lead",
        name: "LeadListing",
        component: () => import("@/views/admin/pages/Lead/Index"),
      },
      {
        path: "Lead/create",
        name: "LeadAdd",
        component: () => import("@/views/admin/pages/Lead/Create"),
      },
      {
        path: "Lead/edit/:id",
        name: "LeadsEdit",
        component: () => import("@/views/admin/pages/Lead/Edit"),
      },
      {
        path: "lead/view/:id",
        name: "LeadsViewMore",
        component: () => import("@/views/admin/pages/Lead/View"),
      },
      {
        path: "lead/newpolicy/:id",
        name: "ConvertToNewPolicy",
        component: () => import("@/views/admin/pages/Lead/ConvertToNewPolicy"),
      },
      // Lead

      // Bussiness
      {
        path: "business",
        name: "BusinessListing",
        component: () => import("@/views/admin/pages/Business/Index"),
      },
      {
        path: "business/create",
        name: "BusinessAdd",
        component: () => import("@/views/admin/pages/Business/Create"),
      },
      {
        path: "business/bulk",
        name: "BusinessBulkAdd",
        component: () => import("@/views/admin/pages/Business/Bulk/Index"),
      },
      {
        path: "business/view/:id",
        name: "BusinessView",
        component: () => import("@/views/admin/pages/Business/View"),
      },
      {
        path: "business/viewmore/:id",
        name: "BusinessViewMore",
        component: () => import("@/views/admin/pages/Business/viewmore"),
      },
      {
        path: "agent",
        name: "AgentListing",
        component: () => import("@/views/admin/pages/Agent/Index"),
      },

      {
        path: "agent/create",
        name: "AgentAdd",
        component: () => import("@/views/admin/pages/Agent/Create"),
      },
      {
        path: "agent/create/:id",
        name: "AgentEdit",
        component: () => import("@/views/admin/pages/Agent/View"),
      },
      {
        path: "broker",
        name: "BrokerListing",
        component: () => import("@/views/admin/pages/Broker/Index"),
      },
      {
        path: "broker/create",
        name: "BrokerAdd",
        component: () => import("@/views/admin/pages/Broker/Create"),
      },
      {
        path: "broker/create/:id",
        name: "BrokerEdit",
        component: () => import("@/views/admin/pages/Broker/View"),
      },
      // X-Motor
      {
        path: "health",
        name: "Health",
        component: () => import("@/views/admin/pages/XMotor/Index"),
      },
      {
        path: "travel",
        name: "Travel",
        component: () => import("@/views/admin/pages/XMotor/travel"),
      },
      {
        path: "nonmotor",
        name: "NonMotor",
        component: () => import("@/views/admin/pages/XMotor/nonmotor"),
      },
      {
        path: "life",
        name: "Life",
        component: () => import("@/views/admin/pages/XMotor/life"),
      },
      {
        path: "other",
        name: "Other",
        component: () => import("@/views/admin/pages/XMotor/other"),
      },

      // Ledger Entry
      {
        path: "agentledger",
        name: "AgentLedger",
        component: () => import("@/views/admin/pages/Ledger/Agent"),
      },
      {
        path: "brokerledger",
        name: "BrokerLedger",
        component: () => import("@/views/admin/pages/Ledger/Broker"),
      },
      {
        path: "customerledger",
        name: "CustomerLedger",
        component: () => import("@/views/admin/pages/Ledger/Customer"),
      },

      {
        path: "ledgerview/:id",
        name: "LedgerView",
        component: () => import("@/views/admin/pages/Ledger/Ledgerview"),
      },
      // 

      
      {
        path: "xmotor/create",
        name: "XMotorAdd",
        component: () => import("@/views/admin/pages/XMotor/Create"),
      },
      {
        path: "xmotor/torenewal/:id",
        name: "RenewalToXmotor",
        component: () => import("@/views/admin/pages/Renewal/xmotortorenewal"),
      },
      {
        path: "xmotor/viewmore/:id",
        name: "XMotorViewMore",
        component: () => import("@/views/admin/pages/XMotor/viewmore"),
      },
      {
        path: "xmotor/view/:id",
        name: "XMotorView",
        component: () => import("@/views/admin/pages/XMotor/View"),
      },
      {
        path: "policyreports",
        name: "PolicyReports",
        component: () => import("@/views/admin/pages/Reports/Index"),
      },
      {
        path: "xmotorpolicyreports",
        name: "XMotorPolicyReports",
        component: () => import("@/views/admin/pages/Reports/XMotorIndex"),
      },
      {
        path: "renewalreports",
        name: "RENEWALReports",
        component: () => import("@/views/admin/pages/Reports/RenewalIndex"),
      },
      {
        path: "leadreports",
        name: "LeadReports",
        component: () => import("@/views/admin/pages/Reports/LeadIndex"),
      },
      {
        path: "banner",
        name: "Banner",
        component: () => import("@/views/admin/pages/Banner/Index"),
      },
      // Renewal
      {
        path: "renewal",
        name: "RenewalListing",
        component: () => import("@/views/admin/pages/Renewal/Index"),
      },
      {
        path: "renewal/health",
        name: "RenewalHealth",
        component: () => import("@/views/admin/pages/Renewal/Health"),
      },
      {
        path: "renewal/travel",
        name: "RenewalTravel",
        component: () => import("@/views/admin/pages/Renewal/Travel"),
      },
      {
        path: "renewal/nonMotor",
        name: "RenewalNonMotor",
        component: () => import("@/views/admin/pages/Renewal/NonMotor"),
      },
      {
        path: "renewal/life",
        name: "RenewalLife",
        component: () => import("@/views/admin/pages/Renewal/Life"),
      },
      {
        path: "renewal/other",
        name: "RenewalOther",
        component: () => import("@/views/admin/pages/Renewal/Other"),
      },
      {
        path: "business/torenewal/:id",
        name: "BusinessToRenewal",
        component: () => import("@/views/admin/pages/Renewal/businesstorenewal"),
      },
      // Renewal

      // Renewal Tab
      // {
      //   path: "renewaltab",
      //   name: "RenewalListingTab",
      //   component: () => import("@/views/admin/pages/Renewal/IndexTab"),
      // },
      // Renewal Tab
      {
        path: "my-earning",
        name: "MyEarning",
        component: () => import("@/views/admin/pages/Earning/Index"),
      },
      {
        path: "my-xm-earning",
        name: "MyXMEarning",
        component: () => import("@/views/admin/pages/Earning/XMIndex"),
      },
      {
        path: "my-new-earning",
        name: "NewEarning",
        // name: "Pagination",
        component: () => import("@/views/admin/pages/Earning/NewIndex"),
        // component: () => import("@/views/admin/pages/Pagination/PaginationIndex"),
      },
      // Quotation
      {
        path: "quotation",
        name: "QuotationListing",
        component: () => import("@/views/admin/pages/Quotation/Index"),
      },
      {
        path: "quotation/create",
        name: "QuotationAdd",
        component: () => import("@/views/admin/pages/Quotation/Create"),
      },
      {
        path: "quotation/create/:id",
        name: "QuotationEdit",
        component: () => import("@/views/admin/pages/Quotation/Create"),
      },
      // Profile
      {
        path: "profile",
        name: "MyProfile",
        component: () => import("@/views/admin/pages/Profile/Index"),
      },
      {
        path: "createOwnSite",
        name: "CreateOwnSite",
        component: () => import("@/views/admin/pages/Createsite/Index"),
      },
      {
        path: "referralhistory",
        name: "ReferralHistory",
        component: () => import("@/views/admin/pages/Profile/referralhistory"),
      },
      {
        path: "profile/signature",
        name: "ProfileSignature",
        component: () => import("@/views/admin/pages/Profile/Signature"),
      },
      // For Demo Pagination
      // {
      //   path: "pagination",
      //   name: "Pagination",
      //   component: () => import("@/views/admin/pages/Pagination/PaginationIndex"),
      // },


    ],
  },
];
