import { BootstrapVue3 } from "bootstrap-vue-3";
import { createApp } from "vue";
import App from "./App.vue";
import { router } from "./routes";

// Import bootstrap styles
import "bootstrap/scss/bootstrap.scss";
import "vue-search-select/dist/VueSearchSelect.css"

import Toaster from "@meforma/vue-toaster";

const app = createApp(App);

router.beforeEach((to, from, next) => {
  //   console.log(to.meta.middleware, from.meta);
  if (to.meta?.middleware === "advisor-auth") {
    let token = localStorage.getItem("@token");
    if (!token) {
      next({
        name: "Login",
      });
    }
    else
    {
      // console.log('token',localStorage.getItem("@token"));
      // console.log('token',JSON.parse(token).is_exists);
      token = JSON.parse(token);
      if(token.is_exists == 'N')
      {
        next({
          name: "Login",
        });
      }
       
    }
  }

  return next();
});

app.use(router);
app.use(BootstrapVue3);
app.use(Toaster, {
  // One of the options
  position: "top",
});

app.mount("#app");
