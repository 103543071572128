<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
/* .select2-container .select2-selection--single {
  height: 100%;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 36px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 36px;
}
.select2-container--default .select2-selection--single {
  border-color: var(--bs-primary);
} */
</style>
